import {graphql} from "gatsby"
import React from "react"
import Layout from "../Page"
import ContactUs from "../../components/ContactUs"
import Elements from '../../templates/Elements/Elements';
import config from "../../config/config"

const BlogPost = ({ data: { silverStripeDataObject }}) => {
    const { elementalContent, metaTags} = silverStripeDataObject.Page;
    const { nextPostTitle, nextPostLink, nextPostSummary, previousPostTitle, previousPostLink, previousPostSummary} = silverStripeDataObject.BlogPost;
    const pageData = {
        pageClass: 'BlogPost', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,      
    };
    var inlineStyle = {};
    return (
        <Layout pageData={pageData}>
            <Elements data={elementalContent}/>
            <div className="related-articles-nav mb-5">
                {(previousPostTitle && previousPostTitle != '#' && previousPostLink && previousPostLink != '#') && (
                <div className="prev">
                    <a href={previousPostLink} className="h3 underline-7px">
                        <svg width='40px' height='27px' className="arrow-ico">
                            <use xlinkHref='/img/icons.svg#arrow-left-alt'></use>
                        </svg>
                        Previous
                    </a>
                    <h4>{previousPostTitle}</h4>
                    {previousPostSummary != '#' && (
                        <p>{previousPostSummary}</p>
                    )}
                </div>
                )}
                {(nextPostTitle != '#' && nextPostLink != '#') && (
                <div className="next">
                    <a href={nextPostLink} className="h3 underline-7px">
                        Next
                        <svg width='40px' height='27px' className="arrow-ico">
                            <use xlinkHref='/img/icons.svg#arrow-right-alt'></use>
                        </svg>
                    </a>
                    <h4>{nextPostTitle}</h4>
                    {nextPostSummary != '#' && (
                        <p>{nextPostSummary}</p>
                    )}
                </div>
                )}
            </div>
        </Layout>
    );
};

export default BlogPost
export const pageQuery = graphql`
    query ($link: String!) {
            silverStripeDataObject(link: { eq: $link }) {
                Page{
                    elementalContent
                    metaTags
                }
                BlogPost{
                    nextPostTitle
                    nextPostLink
                    nextPostSummary
                    previousPostTitle
                    previousPostLink
                    previousPostSummary
                    imagePath
                    summary
                }
            }

    }
`;